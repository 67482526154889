import React from 'react';
import './App.scss';
import logo from './logo_gold.svg';
import Contact from './Contact.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      emailSent: false,
    };
  }
  toggleContact = () => {
    this.setState({
      showContact: !this.state.showContact
    });
    if (this.state.showContact == false) {
      this.setState({
        emailSent: false
      });
    }
  }
  toggleEmailSent = () => {
    this.setState({
      emailSent: !this.state.emailSent
    });
  }
  render() {
    return (
      <div className="main-content">
        <button className="navBtn" onClick={this.toggleContact}>{this.state.showContact ? "Home" : "Contact"}</button>
       
        {this.state.showContact ?
          <Contact emailSent={this.state.emailSent} toggleEmailSent={this.toggleEmailSent} />
        :
          <div className="logo">
            <img src={logo} />
          </div>
        }
        
        <div className="address">
          <p>Al Tajir Family Office Est.</p>
          <p>Pflugstrasse 16</p>
          <p>9490 Vaduz</p>
          <p>Liechtenstein</p>
          <p>+423 793 9131</p>
        </div>
        {/* <Contact /> */}
      </div>
    );
  }
}

export default App;
