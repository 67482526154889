import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import qs from 'querystring'
import ReCAPTCHA from "react-google-recaptcha";
import './App.scss';

const emailService = "https://us-central1-al-tajir.cloudfunctions.net/sendEmail"; //"http://localhost:5001/al-tajir/us-central1/sendEmail"; //https://us-central1-al-tajir.cloudfunctions.net/sendEmail

function ContactForm(props) {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        company: "",
        telephone: "",
        subject: "",
        message: "",
        captcha: props.captchaValue,
      }}
      validate={values => {
        const errors = {};
        if (!props.captchaValue) {
          errors.captcha = 'Required';
        }
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        axios.post(emailService, qs.stringify(values), config)
          .then(function (response) {
            console.log(response);
            props.toggleEmailSent();
          })
          .catch(function (error) {
            console.error(error);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="inputGroup">
            <label>Name:*</label>
            <Field type="text" name="name" />
            <ErrorMessage className="errorMessage" name="name" component="div" />
          </div>
          <div className="inputGroup">
            <label>Email:*</label>
            <Field type="email" name="email" />
            <ErrorMessage className="errorMessage" name="email" component="div" />
          </div>
          <div className="inputGroup">
            <label>Company:</label>
            <Field type="text" name="company" />
            <ErrorMessage className="errorMessage" name="company" component="div" />
          </div>
          <div className="inputGroup">
            <label>Telephone:</label>
            <Field type="tel" name="telephone" />
            <ErrorMessage className="errorMessage" name="telephone" component="div" />
          </div>
          <div className="inputGroup">
            <label>Subject:</label>
            <Field type="text" name="subject" />
            <ErrorMessage className="errorMessage" name="subject" component="div" />
          </div>
          <div className="inputGroup">
            <label>Message:</label>
            <Field component="textarea" name="message" />
            <ErrorMessage className="errorMessage" name="message" component="div" />
          </div>

          <div className="inputGroup captcha">
            <ReCAPTCHA
              sitekey="6LfeqNEUAAAAAP7lkloDZcq_4lKrxeDajk3KeuAF"
              onChange={props.onChangeCaptcha}
            />
            <ErrorMessage className="errorMessage" name="captcha" component="div" />
          </div>

          <button type="submit" disabled={isSubmitting}>
            Send
        </button>
        </Form>
      )}
    </Formik>
  )
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    this.setState({ value });
  }

  render() {
    return <div className="contactForm">
      {this.props.emailSent ?
        <div className="successMsg">
          <h2>Thank you for contacting us.</h2>
          <p>Your message has been forwarded and will be processed as soon as possible.</p>
        </div>
        :
        <div>
          <h2>Contact us</h2>
          <ContactForm toggleEmailSent={this.props.toggleEmailSent} onChangeCaptcha={this.onChangeCaptcha} captchaValue={this.state.value} />
        </div>
      }


    </div>
  };
}

export default Contact;